import React, { type ReactElement, useEffect, useState } from 'react';
import { Flex, Select, DatePicker, Table, notification, Button } from 'antd';
import { type BaseRangePickerProps } from 'rc-picker/lib/PickerInput/RangePicker';
import dayjs, { type Dayjs } from 'dayjs';
import { isEqual, get } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { createApiGateway } from '../../../apiGateway';
import { Layout } from '../../../components/Layout';
import {
  FieldType,
  type ListStatisticsByTableResponse,
  type ListTablesResponse,
  type SortOrder,
  type Table as ApiTable,
} from '../../../apiGateway/auctionStatistics';
import { ButtonWithSelect } from './components/ButtonWithSelect';
import { useSsps } from '../../../hooks/useSsps';
import { useDsps } from '../../../hooks/useDsps';
import { getColumns, getDefaultDimensions } from './utils/generateColumns';
import { groupColumns } from './utils/groupColumns';
import { openInNewTab } from '../../../helpers/openInNewTab';

export type Column = { title: string; dataIndex: string; key: string; sorter?: boolean };

export function StatisticsV2Page(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const apiGateway = createApiGateway();

  const ssps = useSsps();
  const dsps = useDsps();

  const [tables, setTables] = useState<ListTablesResponse>();
  const [activeTable, setActiveTable] = useState<ApiTable | null>();
  const [dateRange, setDateRange] = useState<BaseRangePickerProps<Dayjs>['value']>([dayjs(), dayjs().add(1, 'day')]);
  const [dataSource, setDataSource] = useState<ListStatisticsByTableResponse>();
  const [prevDataSource, setPrevDataSource] = useState<ListStatisticsByTableResponse['rows']>();
  const [columns, setColumns] = useState<{ original: Column[]; display: Column[]; dimensions: Column[] }>({
    original: [],
    display: [],
    dimensions: [],
  });
  const [dimensions, setDimensions] = useState<string[]>(getDefaultDimensions());
  const [pageSize, setPageSize] = useState(15);
  const [order, setOrder] = useState<{ by?: string; direction?: SortOrder }>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [selectedSsp, setSelectedSsp] = useState<number | undefined>();
  const [selectedDsp, setSelectedDsp] = useState<number | undefined>();

  const handleFetchStatistics = async () => {
    try {
      if (!activeTable) return;

      const from = dateRange?.[0];
      const to = dateRange?.[1];

      if (!from || !to) return;

      setLoading(true);

      const response = await apiGateway.auctionStatistics.listStatisticsByTable({
        table: activeTable.key,
        from: from.format('YYYY-MM-DD'),
        to: to.add(1, 'day').format('YYYY-MM-DD'),
        dimensions,
        limit: pageSize,
        offset: (currentPage - 1) * pageSize,
        orderBy: order.by,
        orderDirection: order.direction,
        sspId: selectedSsp,
        dspId: selectedDsp,
      });

      setLoading(false);

      setPrevDataSource(dataSource?.rows);
      setDataSource(response);
    } catch (e) {
      setLoading(false);

      if (e instanceof Error) {
        notification.error({
          message: 'Failed to fetch statistics',
          description: `${e.message}: ${get(e, 'response.data') || 'unknown error'}`,
          duration: 0,
        });
      }
    }
  };

  const handleExport = () => {
    if (!activeTable) return;

    const from = dateRange?.[0];
    const to = dateRange?.[1];

    if (!from || !to) return;

    const url = apiGateway.auctionStatistics.getListStatisticsByTableUrl({
      table: activeTable.key,
      from: from.format('YYYY-MM-DD'),
      to: to.add(1, 'day').format('YYYY-MM-DD'),
      dimensions,
      orderBy: order.by,
      orderDirection: order.direction,
      sspId: selectedSsp,
      dspId: selectedDsp,
      export: true,
    });

    openInNewTab(url, { withToken: true });
  };

  useEffect(() => {
    (async () => {
      const response = await apiGateway.auctionStatistics.listTables();

      setActiveTable(response[0] || null);

      setTables(response);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // To not fetch statistics on the first render
      if (!dataSource) return;

      await handleFetchStatistics();
    })();
  }, [currentPage, pageSize, order]);

  useEffect(() => {
    if (!dataSource || !activeTable) return;

    const newRows = Object.keys(dataSource?.rows?.[0] || {});
    const prevRows = Object.keys(prevDataSource?.[0] || {});

    if (dataSource?.rows?.[0] && !isEqual(newRows, prevRows)) {
      const { regularColumns, dimensionsColumns } = getColumns(activeTable, dimensions);

      setColumns({ original: regularColumns, display: regularColumns, dimensions: dimensionsColumns });
    }
  }, [dataSource, dimensions]);

  return (
    <Layout>
      <Flex gap={'small'} vertical={true}>
        <Flex gap={'small'}>
          <Select
            placeholder={'Table'}
            options={tables?.map((table) => ({
              label: table.name,
              value: table.key,
            }))}
            style={{ width: 200 }}
            value={activeTable ? activeTable.key : undefined}
            onChange={(value) => {
              // getDefaultDimensions
              const selectedTable = tables?.find((table) => table.key === value);

              if (!selectedTable) return;

              setOrder({});
              setDimensions(getDefaultDimensions(selectedTable));
              setActiveTable(selectedTable);
            }}
          />

          <DatePicker.RangePicker value={dateRange} onChange={setDateRange} />

          <Select
            placeholder={'Select SSP'}
            options={ssps.map((ssp) => {
              return {
                label: `[${ssp.id}] ${ssp.name}`,
                value: ssp.id,
              };
            })}
            style={{ width: 200 }}
            showSearch={true}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            allowClear={true}
            onChange={setSelectedSsp}
            loading={ssps.length === 0}
          />

          <Select
            placeholder={'Select DSP'}
            options={dsps.map((dsp) => {
              return {
                label: `[${dsp.id}] ${dsp.name}`,
                value: dsp.id,
              };
            })}
            style={{ width: 200 }}
            showSearch={true}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            allowClear={true}
            onChange={setSelectedDsp}
            loading={dsps.length === 0}
          />
        </Flex>

        <Flex gap={'small'}>
          <Button type={'primary'} onClick={handleFetchStatistics}>
            Fetch Statistics
          </Button>

          <ButtonWithSelect
            title={'Dimensions'}
            options={(activeTable ? activeTable.fields : [])
              .filter((field) => field.type === FieldType.DIMENSION)
              .map((field) => ({
                label: field.name,
                value: field.key,
              }))}
            handleCheckboxChange={(checkedColumns) => {
              if (!checkedColumns.length) {
                return notification.error({ message: 'At least one dimension should be selected' });
              }

              setDimensions(checkedColumns);
            }}
            value={dimensions}
          />

          <ButtonWithSelect
            title={'Columns'}
            options={columns.original.map((column) => ({
              label: column.title,
              value: column.key,
            }))}
            handleCheckboxChange={(checkedColumns) => {
              if (!checkedColumns.length) {
                return notification.error({ message: 'At least one column should be selected' });
              }

              setColumns((prevColumns) => ({
                ...prevColumns,
                display: columns.original.filter((column) => checkedColumns.includes(column.key)),
              }));
            }}
            value={columns.display.map((column) => column.key)}
          />

          <Button onClick={handleExport}>Export</Button>
        </Flex>

        <Table
          style={{ overflowX: 'auto' }}
          size="small"
          bordered={true}
          dataSource={dataSource?.rows}
          columns={groupColumns([...columns.dimensions, ...columns.display])}
          pagination={{
            defaultPageSize: pageSize,
            pageSizeOptions: [15, 100, 500],
            total: dataSource?.count,
            showSizeChanger: true,
            hideOnSinglePage: true,
          }}
          loading={loading}
          onChange={(pagination, filters, sorter) => {
            if (Array.isArray(sorter) === false && sorter.columnKey) {
              setOrder({
                by: sorter.columnKey as string,
                direction: { descend: 'DESC', ascend: 'ASC' }[sorter.order!] as SortOrder,
              });
            }

            if (pagination.current) {
              setCurrentPage(pagination.current);
            }

            if (pagination.pageSize) {
              setPageSize(pagination.pageSize);
            }
          }}
        />
      </Flex>
    </Layout>
  );
}
